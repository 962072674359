import * as Sentry from '@sentry/browser'
import { mode } from './config'

if (process.env.APP_ENV === mode.PRODUCTION) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        beforeSend(event) {
            if (event.exception) {
                const error = event.exception.values[0]
                if (error.type === 'ReferenceError' && error.value.includes('Notification is not defined')) {
                    return null
                }
            }
            return event
        },
    })
}
